<template>
  <div>
    <component
      v-for="(element, index) in visibleContent"
      :key="`content_element_${index}`"
      :is="getElementComponent(element)"
      :data="element"
      v-bind="{ ...element.properties }"
    ></component>
  </div>
</template>

<script>
import { ContentEnums } from '@/enum/content.js';
import { mapState } from 'vuex';

import textBox from './text-box.vue';
import advertTextBox from './advert-text-box.vue';
import companyBenefits from './company-benefits.vue';
import htmlContent from './html-content.vue';
import contentGrid from './content-grid.vue';
import companyDepartments from './company-departments.vue';
import expanderContent from './expander-content.vue';
import accordionContent from './accordion-content.vue';
import imageContent from './image-content.vue';
import imageGrid from './image-grid.vue';
import videoBox from './video-box.vue';
import swiper from './content-swiper-alt.vue';
import testimonials from './testimonial-boxes.vue';

export default {
  name: 'content-area',
  props: {
    content: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      isInternal: 'isInternal'
    }),

    visibleContent() {
      return this.content.filter((x) => {
        if (x.visibility === ContentEnums.visibility.Always) {
          return true;
        }

        if (
          x.visibility === ContentEnums.visibility.InternalOnly &&
          this.isInternal
        ) {
          return true;
        }

        if (
          x.visibility === ContentEnums.visibility.ExternalOnly &&
          !this.isInternal
        ) {
          return true;
        }

        return false;
      });
    }
  },

  methods: {
    getElementComponent(contentElement) {
      if (
        contentElement.componentType === ContentEnums.componentTypes.TextBox
      ) {
        return textBox;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.AdvertTextBox
      ) {
        return advertTextBox;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.CompanyBenefits
      ) {
        return companyBenefits;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.HtmlContent
      ) {
        return htmlContent;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.ContentGrid
      ) {
        return contentGrid;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.CompanyDepartments
      ) {
        return companyDepartments;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.Expander
      ) {
        return expanderContent;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.Accordion
      ) {
        return accordionContent;
      }

      if (contentElement.componentType === ContentEnums.componentTypes.Image) {
        return imageContent;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.ImageGrid
      ) {
        return imageGrid;
      }

      if (
        contentElement.componentType === ContentEnums.componentTypes.VideoBox
      ) {
        return videoBox;
      }

      if (contentElement.componentType === ContentEnums.componentTypes.Swiper) {
        return swiper;
      }

      if (
        contentElement.componentType ===
        ContentEnums.componentTypes.Testimonials
      ) {
        return testimonials;
      }

      return '';
    }
  }
};
</script>
